import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'next-i18next';

import CreditCardsList from 'views/common/components/UI/Payment/CreditCardsList';
import CreditCardInfo from 'views/common/components/UI/Payment/CreditCardInfo';
import PaymentOptions from 'views/common/components/UI/Payment/PaymentOptions';
import ReCAPTCHA from 'react-google-recaptcha';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const PaymentSection = (props) => {
  const {
    creditCards,
    creditCardForm,
    handleCreditCardFormChange,
    handleDateChange,
    addNewCreditCard,
    creditCardError,
    selectedCreditCardId,
    setSelectedCreditCardId,
    creditCardPaymentError,
    currentPaymentSectionDisplay,
    handleCurrentPaymentSectionDisplayChange,
    showBillingInfo,
    countries,
    handleBillingAddressChange,
    handleCountryChange,
    selectedCountryStates,
    availableCardsTypes,
    ccRecaptchaRef,
    handleCCRecaptchaClick = () => {},
    isForceCaptcha = false,
  } = props;

  const { t } = useTranslation('fe_er_payment_component');

  const currentDisplay = () => {
    switch (currentPaymentSectionDisplay) {
      case 'credit_cards':
        return (
          <CreditCardsList
            cards={creditCards}
            selectedCreditCardId={selectedCreditCardId}
            setSelectedCreditCardId={setSelectedCreditCardId}
            creditCardPaymentError={creditCardPaymentError}
            handleCurrentPaymentSectionDisplayChange={
              handleCurrentPaymentSectionDisplayChange
            }
          />
        );
      case 'credit_card_form':
        return (
          <CreditCardInfo
            creditCardForm={creditCardForm}
            handleCreditCardFormChange={handleCreditCardFormChange}
            handleBillingAddressChange={handleBillingAddressChange}
            handleDateChange={handleDateChange}
            addNewCreditCard={addNewCreditCard}
            creditCardError={creditCardError || creditCardPaymentError}
            showBillingAddress={showBillingInfo}
            countries={countries}
            handleCountryChange={handleCountryChange}
            states={selectedCountryStates}
          />
        );
      default:
        return (
          <PaymentOptions
            currentPaymentSectionDisplay={currentPaymentSectionDisplay}
            handleCurrentPaymentSectionDisplayChange={
              handleCurrentPaymentSectionDisplayChange
            }
            availableCardsTypes={availableCardsTypes}
          />
        );
    }
  };

  return (
    <>
      <Box mt={2} id="payment-section">
        {currentDisplay()}
        {(currentPaymentSectionDisplay === 'credit_cards'
          || currentPaymentSectionDisplay === 'credit_card_form')
          && isForceCaptcha && (
            <Box my={2}>
              <ReCAPTCHA
                sitekey={envVars.RECAPTCHA_SITE_KEY}
                ref={ccRecaptchaRef}
                onChange={(token) => {
                  handleCCRecaptchaClick(token);
                }}
              />
            </Box>
        )}
      </Box>
      {currentPaymentSectionDisplay == 'credit_card_form' && (
        <>
          <Box mt={3} mb={2}>
            <Divider light />
          </Box>
          <Button
            color="secondary"
            onClick={() => handleCurrentPaymentSectionDisplayChange('payment_options')}
            startIcon={<ArrowBackIcon />}>
            {t('fe_er_payment_component:show_payment_methods')}
          </Button>
        </>
      )}
    </>
  );
};

export default PaymentSection;
