import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  '& .mobileActionsHolder': {
    minHeight: theme.spacing(6.25),
  },
  '& .mobileActions': {
    padding: theme.spacing(1.5, 1.5),
    backgroundColor: theme.palette.common.white,
    borderTop: `2px solid ${theme.palette.grey['100']}`,

    '&.nowFixed': {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      zIndex: '2',
    },
  },
}));

const ActionBar = (props) => {
  const { className, children } = props;
  const theme = useTheme();
  const [isMobileActionsFixed, setMobileActionsFixed] = useState(true);
  const isScreenSM = useMediaQuery(theme.breakpoints.down('xl'));
  const mobileActionsHolderRef = useRef(null);
  useEffect(() => {
    if (isScreenSM && mobileActionsHolderRef && process.browser) {
      window.addEventListener('scroll', () => {
        if (mobileActionsHolderRef.current) {
          const mobileActionsHolderRefOffsetTop = mobileActionsHolderRef.current.offsetTop;
          if (
            window.scrollY + window.innerHeight - 55
            > mobileActionsHolderRefOffsetTop
          ) {
            setMobileActionsFixed(false);
          } else {
            setMobileActionsFixed(true);
          }
        }
      });
    }
  }, [isScreenSM, mobileActionsHolderRef]);

  return (
    <Root className={className}>
      <Box className="mobileActionsHolder" ref={mobileActionsHolderRef}>
        <Box
          className={`mobileActions ${isMobileActionsFixed ? 'nowFixed' : ''}`}>
          {children}
        </Box>
      </Box>
    </Root>
  );
};

export { ActionBar };
