import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';

import { styled } from '@mui/material/styles';

const PREFIX = 'CheckBoxCard';

const classes = {
  cardBox: `${PREFIX}-cardBox`,
  active: `${PREFIX}-active`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  description: `${PREFIX}-description`,
  descriptionSmallMargin: `${PREFIX}-descriptionSmallMargin`,
  rightSideBox: `${PREFIX}-rightSideBox`,
  rightSideMobileBox: `${PREFIX}-rightSideMobileBox`,
  detailsBox: `${PREFIX}-detailsBox`,
  formlabel: `${PREFIX}-formlabel`,
  checkBox: `${PREFIX}-checkBox`,
  error: `${PREFIX}-error`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('span')(({ theme, size, checkboxpostion }) => ({
  [`& .${classes.cardBox}`]: {
    backgroundColor: theme.palette.grey.A000,
    borderRadius: 7,
    padding: size == 'small' ? theme.spacing(1.25) : theme.spacing(2, 1.5),
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1.25),
    },
  },
  [`& .${classes.active}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
  },
  [`& .${classes.title}`]: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    fontSize:
      size == 'small'
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(16),
  },
  [`& .${classes.subtitle}`]: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    fontSize:
      size == 'small'
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(16),
  },
  [`& .${classes.description}`]: {
    color: theme.palette.grey['300'],
    marginTop: size == 'small' ? theme.spacing(1) : theme.spacing(2),
  },
  [`& .${classes.descriptionSmallMargin}`]: {
    color: theme.palette.grey['300'],
    marginTop: theme.spacing(0.5),
  },
  [`& .${classes.rightSideBox}`]: {
    marginTop: theme.spacing(0.375),
  },
  [`& .${classes.rightSideMobileBox}`]: {
    width: `calc(100% - ${theme.spacing(3.75)})`,
    marginTop: theme.spacing(0.375),
    marginLeft: checkboxpostion == 'left' ? theme.spacing(3.75) : 0,
    marginRight: checkboxpostion == 'right' ? theme.spacing(3.75) : 0,
  },
  [`& .${classes.detailsBox}`]: {
    width: `calc(100% - ${theme.spacing(3.75)})`,
    marginLeft: checkboxpostion == 'left' ? theme.spacing(3.75) : 0,
    marginRight: checkboxpostion == 'right' ? theme.spacing(3.75) : 0,
  },
  [`& .${classes.formlabel}`]: {
    '&.MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: 0,
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: theme.spacing(-1.5),
    },
  },
  [`& .${classes.checkBox}`]: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
  },
  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.A000,
    borderColor: theme.palette.error.main,
    '& .MuiCheckbox-root': {
      color: theme.palette.error.main,
    },
  },
}));

const CheckBoxCard = (props) => {
  const {
    title,
    subtitle,
    description,
    rightSideChildren,
    details,
    name,
    checked,
    onChange,
    size = 'large',
    checkBoxPostion = 'left',
    value,
    rightSideMobileFullWidth = false,
    error,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Root size={size} checkboxpostion={checkBoxPostion}>
      <Box
        className={`${classes.cardBox} ${error && classes.error} ${
          checked ? classes.active : ''
        }`}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}>
          <Grid item xs>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checked}
                  onChange={onChange}
                  name={name}
                  value={value}
                  color="primary"
                  className={classes.checkBox}
                />
              )}
              labelPlacement={checkBoxPostion == 'right' ? 'start' : 'end'}
              className={`${classes.formlabel}`}
              label={(
                <>
                  <Typography variant="h5" className={classes.title}>
                    {title}
                  </Typography>

                  {subtitle && (
                    <Typography variant="h6" className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  )}

                  {description && (
                    <Typography
                      variant="caption"
                      className={
                        subtitle
                          ? classes.descriptionSmallMargin
                          : classes.description
                      }
                      component="div">
                      {description}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>

          {rightSideChildren && checkBoxPostion == 'left' && (
            <Grid
              item
              xs={isMobile && rightSideMobileFullWidth ? 12 : true}
              className={
                isMobile && rightSideMobileFullWidth
                  ? classes.rightSideMobileBox
                  : classes.rightSideBox
              }>
              {rightSideChildren}
            </Grid>
          )}
        </Grid>

        {details && (
          <Box mt={1} className={classes.detailsBox}>
            {details}
          </Box>
        )}
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Root>
  );
};

export { CheckBoxCard };
