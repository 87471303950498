import React from 'react';
import { styled } from '@mui/material/styles';
import { RadioGroup, Grid } from '@mui/material';

import { RadioButtonCard } from 'views/common/components';

const PREFIX = 'PaymentOptions';

const classes = {
  cardImg: `${PREFIX}-cardImg`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  [`& .${classes.cardImg}`]: {
    width: theme.spacing(4.5),
    marginRight: theme.spacing(1.25),
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

const PaymentOptions = (props) => {
  const {
    currentPaymentSectionDisplay,
    handleCurrentPaymentSectionDisplayChange,
    availableCardsTypes,
  } = props;

  const options = [
    {
      name: 'Credit or Debit Card',
      key: 'credit_cards',
      icons: availableCardsTypes,
    },
    // {
    //   name: 'PayPal',
    //   key: 'paypal',
    //   icons: ['paypal.svg'],
    // },
    // {
    //   name: 'Apple Pay',
    //   key: 'applepal',
    //   icons: ['apple-pay.svg'],
    // },
    // {
    //   name: 'Google Pay',
    //   key: 'googlepay',
    //   icons: ['google-pay.svg'],
    // },
  ];

  const getFormattedOptions = () => options.map((option) => ({
    value: option.key,
    title: <span>{option.name}</span>,
    rightSideChildren: (
      <Grid container justifyContent="flex-end">
        {option.icons.map((icon) => (
          <Grid item key={icon}>
            <img
              src={`/imgs/payments/${icon}.svg`}
              alt={option.key}
              className={classes.cardImg}
              />
          </Grid>
        ))}
      </Grid>
    ),
  }));

  return (
    <StyledRadioGroup
      aria-label="gender"
      value={currentPaymentSectionDisplay || ''}
      onChange={(e) => {
        handleCurrentPaymentSectionDisplayChange(e.target.value);
      }}>
      <Grid container spacing={3}>
        {getFormattedOptions().map((option) => (
          <Grid item xs={12} key={option.key}>
            <RadioButtonCard
              key={option.value}
              radioButtonDetails={option}
              activeValue={currentPaymentSectionDisplay}
              size="small"
              radioBtnPostion="left"
            />
          </Grid>
        ))}
      </Grid>
    </StyledRadioGroup>
  );
};

export default PaymentOptions;
