import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Radio,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'RadioButtonCardDemo';

const classes = {
  rootBox: `${PREFIX}-rootBox`,
  card: `${PREFIX}-card`,
  active: `${PREFIX}-active`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  description: `${PREFIX}-description`,
  descriptionSmallMargin: `${PREFIX}-descriptionSmallMargin`,
  rightSideBox: `${PREFIX}-rightSideBox`,
  rightSideMobileBox: `${PREFIX}-rightSideMobileBox`,
  detailsBox: `${PREFIX}-detailsBox`,
  formlabel: `${PREFIX}-formlabel`,
  rightCheckBox: `${PREFIX}-rightCheckBox`,
  checkBox: `${PREFIX}-checkBox`,
  error: `${PREFIX}-error`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme, size, radiobtnpostion }) => ({
  [`&.${classes.card}`]: {
    backgroundColor: theme.palette.grey.A000,
    borderRadius: 7,
    padding: size == 'small' ? theme.spacing(1.25) : theme.spacing(2, 1.5),
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1.25),
    },
  },
  [`&.${classes.active}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
  },
  [`& .${classes.title}`]: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    fontSize:
      size == 'small'
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(16),
  },
  [`& .${classes.subtitle}`]: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    fontSize:
      size == 'small'
        ? theme.typography.pxToRem(13)
        : theme.typography.pxToRem(14),
  },
  [`& .${classes.description}`]: {
    color: theme.palette.grey['300'],
    marginTop: size == 'small' ? theme.spacing(1) : theme.spacing(2),
  },
  [`& .${classes.descriptionSmallMargin}`]: {
    color: theme.palette.grey['300'],
    marginTop: theme.spacing(0.5),
  },
  [`& .${classes.rightSideBox}`]: {
    marginTop: theme.spacing(0.375),
  },
  [`& .${classes.rightSideMobileBox}`]: {
    width: `calc(100% - ${theme.spacing(3.75)})`,
    marginTop: theme.spacing(0.375),
    marginLeft: radiobtnpostion == 'left' ? theme.spacing(3.75) : 0,
    marginRight: radiobtnpostion == 'right' ? theme.spacing(3.75) : 0,
  },
  [`& .${classes.detailsBox}`]: {
    width: `calc(100% - ${theme.spacing(3.75)})`,
    marginLeft: radiobtnpostion == 'left' ? theme.spacing(3.75) : 0,
    marginRight: radiobtnpostion == 'right' ? theme.spacing(3.75) : 0,
  },
  [`& .${classes.formlabel}`]: {
    '&.MuiFormControlLabel-root': {
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: 0,
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: theme.spacing(-1.5),
    },
  },
  [`& .${classes.checkBox}`]: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
  },
  [`&.${classes.error}`]: {
    backgroundColor: theme.palette.error.A000,
    borderColor: theme.palette.error.main,
    '& .MuiRadio-root': {
      color: theme.palette.error.main,
    },
  },
}));

const RadioButtonCard = (props) => {
  const {
    radioButtonDetails,
    activeValue,
    size = 'large',
    radioBtnPostion = 'left',
    rightSideMobileFullWidth = false,
    error,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <StyledBox
        size={size}
        radiobtnpostion={radioBtnPostion}
        className={`${classes.card} ${error && classes.error} ${
          radioButtonDetails.value == activeValue ? classes.active : ''
        }`}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}>
          <Grid item xs>
            <FormControlLabel
              value={radioButtonDetails.value}
              control={<Radio color="primary" className={classes.checkBox} />}
              labelPlacement={radioBtnPostion == 'right' ? 'start' : 'end'}
              className={classes.formlabel}
              label={(
                <>
                  <Typography variant="h5" className={classes.title}>
                    {radioButtonDetails.title}
                  </Typography>

                  {radioButtonDetails.subtitle && (
                    <Typography variant="h6" className={classes.subtitle}>
                      {radioButtonDetails.subtitle}
                    </Typography>
                  )}

                  {radioButtonDetails.description && (
                    <Typography
                      variant="caption"
                      className={
                        radioButtonDetails.subtitle
                          ? classes.descriptionSmallMargin
                          : classes.description
                      }
                      component="div">
                      {radioButtonDetails.description}
                    </Typography>
                  )}
                </>
              )}
            />
          </Grid>

          {radioButtonDetails.rightSideChildren && radioBtnPostion == 'left' && (
            <Grid
              item
              xs={isMobile && rightSideMobileFullWidth ? 12 : true}
              className={
                isMobile && rightSideMobileFullWidth
                  ? classes.rightSideMobileBox
                  : classes.rightSideBox
              }>
              {radioButtonDetails.rightSideChildren}
            </Grid>
          )}
        </Grid>

        {radioButtonDetails.details && (
          <Box mt={1} className={classes.detailsBox}>
            {radioButtonDetails.details}
          </Box>
        )}
      </StyledBox>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export { RadioButtonCard };
