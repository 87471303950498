import React from 'react';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { ApplyCredit } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';
import PaymentSection from 'views/common/components/UI/Payment/PaymentSection';

const Payment = (props) => {
  const {
    applyCode,
    giftCodeError,
    giftCode,
    handleCodeChange,
    giftCreditIsApplied,
    handleGiftCreditRemove,
    creditCards,
    creditCardForm,
    handleCreditCardFormChange,
    handleDateChange,
    addNewCreditCard,
    creditCardError,
    selectedCreditCardId,
    setSelectedCreditCardId,
    creditCardPaymentError,
    currentPaymentSectionDisplay,
    handleCurrentPaymentSectionDisplayChange,
    showBillingInfo,
    countries,
    handleBillingAddressChange,
    handleCountryChange,
    selectedCountryStates,
    availableCardsTypes,
    isForceCaptcha,
    ccRecaptchaRef,
    handleCCRecaptchaClick,
  } = props;

  const { t } = useTranslation('fe_er_payment_component');

  return (
    <Box>
      <Box mt={4} mb={3} id="store-credit">
        <ApplyCredit
          code={giftCode}
          handleCodeChange={handleCodeChange}
          giftCodeError={giftCodeError}
          applyCode={applyCode}
          creditIsApplied={giftCreditIsApplied}
          handleRemove={handleGiftCreditRemove}
        />
      </Box>

      <Divider light />

      <Box mt={4}>
        <Typography500 variant="h4">
          {t('fe_er_payment_component:payment_info')}
        </Typography500>

        <PaymentSection
          creditCards={creditCards}
          creditCardForm={creditCardForm}
          handleCreditCardFormChange={handleCreditCardFormChange}
          handleDateChange={handleDateChange}
          addNewCreditCard={addNewCreditCard}
          creditCardError={creditCardError}
          selectedCreditCardId={selectedCreditCardId}
          setSelectedCreditCardId={setSelectedCreditCardId}
          creditCardPaymentError={creditCardPaymentError}
          currentPaymentSectionDisplay={currentPaymentSectionDisplay}
          handleCurrentPaymentSectionDisplayChange={
            handleCurrentPaymentSectionDisplayChange
          }
          showBillingInfo={showBillingInfo}
          countries={countries}
          handleBillingAddressChange={handleBillingAddressChange}
          handleCountryChange={handleCountryChange}
          selectedCountryStates={selectedCountryStates}
          availableCardsTypes={availableCardsTypes}
          isForceCaptcha={isForceCaptcha}
          ccRecaptchaRef={ccRecaptchaRef}
          handleCCRecaptchaClick={handleCCRecaptchaClick}
        />
      </Box>
    </Box>
  );
};

export { Payment };
