import React from 'react';
import {
  InputAdornment,
  Slider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  FormHelperText,
} from '@mui/material';

import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  '& .formControlLabel': {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.3, 0.6),
  },
  // slider
  '& .railOverride': {
    height: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
  },
  '& .trackOverride': {
    height: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  '& .thumbOverride': {
    boxShadow: '0px 0px 2px rgba(61, 61, 61, 0.36)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    '&$sliderActiveOverride': {
      boxShadow: '0px 0px 0px 10px rgb(254 123 16 / 16%)',
    },
  },
  '& .sliderActiveOverride': {},
  '& .sliderGrid': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '& .sliderRoot': {
    height: theme.spacing(2),
    padding: theme.spacing(1, 0),
  },
}));

const ValueSlider = (props) => {
  const {
    label,
    inputValue,
    sliderValue,
    onInputChange,
    onBlur,
    sign,
    sliderMin,
    sliderMax,
    error,
  } = props;

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="outlined-adornment-amount"
              classes={{ formControl: 'formControlLabel' }}
              color="secondary">
              {label}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={inputValue || ''}
              onChange={(e) => onInputChange(e.target.value)}
              onBlur={(e) => onBlur(e.target.value)}
              startAdornment={
                sign && <InputAdornment position="start">{sign}</InputAdornment>
              }
              labelWidth={60}
              error={Boolean(error)}
              color="secondary"
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} className="sliderGrid">
          <Slider
            value={sliderValue || 0}
            min={sliderMin}
            max={sliderMax}
            onChange={(e, value) => onBlur(value)}
            aria-labelledby="continuous-slider"
            classes={{
              root: 'sliderRoot',
              rail: 'railOverride',
              track: 'trackOverride',
              thumb: 'thumbOverride',
              active: 'sliderActiveOverride',
            }}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export { ValueSlider };
