import React from 'react';
import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepHeader from 'views/common/components/UI/CardWithHeader/StepHeader';

const PREFIX = 'BenfitItem';

const classes = {
  card: `${PREFIX}-card`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.card}`]: {
    padding: theme.spacing(3),
    borderRadius: 15,
    boxShadow: '0px 0px 6px rgba(0,0,0,0.05)',
  },
}));

const CardWithHeader = ({ title, stepNumber, children }) => (
  <StyledBox>
    <Card className={classes.card}>
      {title && <StepHeader stepNumber={stepNumber} title={title} />}

      <Box mt={title && 2}>{children}</Box>
    </Card>
  </StyledBox>
);

export { CardWithHeader };
