import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography500 from 'views/common/components/UI/Typography500';

const PREFIX = 'BenfitItem';

const classes = {
  stepNumber: `${PREFIX}-stepNumber`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.stepNumber}`]: {
    borderRadius: '50%',
    border: '2px solid',
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
    fontWeight: '400',
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      marginRight: theme.spacing(3.5),
    },
  },
}));

const StepHeader = ({ title, stepNumber }) => (
  <StyledBox>
    <Typography500 variant="h3">
      {stepNumber && <span className={classes.stepNumber}>{stepNumber}</span>}
      {title}
    </Typography500>
  </StyledBox>
);

export default StepHeader;
