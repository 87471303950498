import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Button, RadioGroup, Grid, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';

import { FormErrors, RadioButtonCard } from 'views/common/components';

const PREFIX = 'CreditCardsList';

const classes = {
  lightTxt: `${PREFIX}-lightTxt`,
  cardImg: `${PREFIX}-cardImg`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.lightTxt}`]: {
    color: theme.palette.secondary.light,
  },

  [`& .${classes.cardImg}`]: {
    width: theme.spacing(4.5),
    marginRight: theme.spacing(1.25),
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

const CreditCardsList = (props) => {
  const {
    cards,
    selectedCreditCardId,
    setSelectedCreditCardId,
    creditCardPaymentError,
    handleCurrentPaymentSectionDisplayChange,
  } = props;

  const { t } = useTranslation('fe_er_payment_component');

  const getFormattedCards = () => cards.map((card) => ({
    value: card.id,
    title: (
      <span>
        <img
          src={`/imgs/payments/${card.type_identifier}.svg`}
          alt={card.type_name}
          className={classes.cardImg}
          />
        {`${card.type_name} **** ${card.last_digits}`}
      </span>
    ),
    rightSideChildren: (
      <Grid
        container
        justifyContent="space-between"
        spacing={1}
        alignItems="center">
        <Grid item>
          <Typography className={classes.lightTxt}>
            {`${card.first_name} ${card.last_name}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.lightTxt}>
            {dayjs()
              .set('month', card.month - 1)
              .set('year', card.year)
              .format('MM/YYYY')}
          </Typography>
        </Grid>
      </Grid>
    ),
  }));

  return (
    <Root>
      <RadioGroup
        aria-label="gender"
        value={selectedCreditCardId || ''}
        onChange={(e) => {
          setSelectedCreditCardId(+e.target.value);
        }}>
        <Grid container spacing={3}>
          {cards
            && getFormattedCards().map((card) => (
              <Grid item xs={12} key={card.value}>
                <>
                  <RadioButtonCard
                    key={card.value}
                    radioButtonDetails={card}
                    activeValue={selectedCreditCardId}
                    size="small"
                    radioBtnPostion="left"
                    rightSideMobileFullWidth
                  />
                  {creditCardPaymentError
                    && creditCardPaymentError.errors
                    && selectedCreditCardId === card.value && (
                      <FormErrors
                        errorMsgs={creditCardPaymentError}
                        errorField="credit_card_id"
                      />
                  )}
                </>
              </Grid>
            ))}
        </Grid>
      </RadioGroup>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}>
        <Button
          onClick={() => handleCurrentPaymentSectionDisplayChange('payment_options')}
          startIcon={<ArrowBackIcon />}>
          {t('fe_er_payment_component:show_payment_methods')}
        </Button>
        <Button
          onClick={() => handleCurrentPaymentSectionDisplayChange('credit_card_form')}
          color="primary">
          {t('fe_er_payment_component:add_new_card')}
        </Button>
      </Box>
    </Root>
  );
};

export default CreditCardsList;
