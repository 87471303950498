import React from 'react';
import { Typography, Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
} from '@mui/lab';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import RoomIcon from '@mui/icons-material/Room';
import Typography500 from 'views/common/components/UI/Typography500';

const Root = styled('div')(({ theme }) => ({
  '& .mainTitle': {
    marginBottom: theme.spacing(0.5),
  },
  '& .timeIconBox': {
    color: theme.palette.secondary.light,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      marginRight: theme.spacing(0.5),
      color: theme.palette.grey[300],
    },
  },
  '& .timieLineRoot': {
    alignItems: 'flex-start',
    margin: 0,
    paddingLeft: 0,
  },
  '& .timieLineItemRoot': {
    marginTop: theme.spacing(-1.5),
    minWidth: '20%',
    '&:before': {
      content: 'none',
    },
  },
  '& .timieLineDotRoot': {
    marginBottom: 0,
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1.5),
    padding: 0,
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    boxShadow: 'none',
  },
  '& .timieLineConnectorRoot': {
    backgroundColor: theme.palette.grey[200],
  },
  '& .timieLineContentRoot': {
    padding: theme.spacing(0.75, 1.25),
  },
}));

const TourStartAndEnd = (props) => {
  const {
    title,
    pickupTitle,
    dropoffTitle,
    pickupTime,
    dropoffTime,
    pickupLocation,
    dropoffLocation,
    showIcon = true,
    pickupComponent,
    dropoffComponent,
  } = props;

  return (
    <>
      <Root>
        <Typography500 variant="h5" className="mainTitle">
          {title}
        </Typography500>
        <Timeline classes={{ root: 'timieLineRoot' }}>
          <TimelineItem classes={{ root: 'timieLineItemRoot' }}>
            <TimelineSeparator>
              <TimelineDot classes={{ root: 'timieLineDotRoot' }} />
              <TimelineConnector classes={{ root: 'timieLineConnectorRoot' }} />
            </TimelineSeparator>
            <TimelineContent classes={{ root: 'timieLineContentRoot' }}>
              <Box>
                <Typography500 variant="h6">{pickupTitle}</Typography500>
                <Box className="timeIconBox">
                  {showIcon && <InsertInvitationIcon />}
                  {pickupTime ? (
                    <Typography variant="body2">{pickupTime}</Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                </Box>
                <Box className="timeIconBox" mb={1}>
                  {showIcon && <RoomIcon />}
                  {pickupLocation ? (
                    <Typography variant="body2">{pickupLocation}</Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                </Box>
                {pickupComponent}
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem classes={{ root: 'timieLineItemRoot' }}>
            <TimelineSeparator>
              <TimelineDot classes={{ root: 'timieLineDotRoot' }} />
            </TimelineSeparator>
            <TimelineContent classes={{ root: 'timieLineContentRoot' }}>
              <Box>
                <Typography500 variant="h6">{dropoffTitle}</Typography500>
                <Box className="timeIconBox">
                  {showIcon && <InsertInvitationIcon />}
                  {dropoffTime ? (
                    <Typography variant="body2">{dropoffTime}</Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                </Box>
                <Box className="timeIconBox">
                  {showIcon && <RoomIcon />}
                  {dropoffLocation ? (
                    <Typography variant="body2">{dropoffLocation}</Typography>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                </Box>
                {dropoffComponent}
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Root>
    </>
  );
};

export { TourStartAndEnd };
